import React from "react"
import Layout from "../components/Layout"
import { Link } from "gatsby"
import thanks_icon from "../static/images/icons/thanks.png"

const ContactSuccessPage = () => (
    <Layout>
        <div style={{
            marginBottom: '2em',
            padding: '2em'
        }}>
            <img src={thanks_icon} style={{
                width: '50px'
            }} alt="Erfolg!"/>
            <h1 style={{
                color: '#024873',
                textTransform: 'uppercase',
                fontSize: '1.4em'
            }}>Vielen Dank!</h1>
            <p style={{
                color: '#707070'
            }}>Ihre Nachricht wurde erfolgreich abgesendet. Ich melde mich bald bei Ihnen.</p>
            <Link to="/" className="button orange">zurück zur Startseite</Link>
        </div>
    </Layout>
);

export default ContactSuccessPage